export interface DatepickerPorts {
  dateChanged: PortFromElm<string>
  datepickerOpen: PortFromElm<boolean>
  fetchRelativePosition: PortFromElm<string>
  setRelativePosition: PortToElm<{
    boundingClientRect: DOMRect | null
    innerHeight: number
    innerWidth: number
  }>
}

export const setupDatepickerPorts = (elmApp: ElmApp<DatepickerPorts>): void => {
  elmApp.ports.fetchRelativePosition.subscribe((className: string) => {
    const picker = document.querySelector(`.${className}`)

    elmApp.ports.setRelativePosition.send({
      boundingClientRect: picker instanceof HTMLElement ? picker.getBoundingClientRect() : null,
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth
    })
  })

  elmApp.ports.datepickerOpen.subscribe((isOpen: boolean) => {
    document.body.classList.toggle("--datepicker-open", isOpen)
  })
}
